import {
  formatCurrencyCompactEnUs,
  formatCurrencyEnUs,
  formatPercentage,
} from '@zorro/shared/formatters';
import { StandardizedEmployeeAllowance } from '~/lib/allowance';
import { PlanComparisonData } from '~/types/planComparison';
import {
  EmployeeData,
  EmployeeScenario,
  IPlan,
  Plan,
  SheetData,
} from '~/types/sheet';

export function formatPremiumForDisplay(
  premium: number,
  isPerEmployeeMode: boolean
) {
  return isPerEmployeeMode || premium < 1000
    ? formatCurrencyEnUs(premium, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : formatCurrencyCompactEnUs(premium, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      });
}

export const calculatePremium = (
  sum: number,
  employeeModeFactor: number,
  isPerEmployeeMode: boolean,
  totalFees: number
) => {
  const premium = sum * employeeModeFactor + totalFees;

  return formatPremiumForDisplay(premium, isPerEmployeeMode);
};

export const calculateChangeRatio = (
  zorroValue: number,
  currentValue: number
) => {
  const ratio = 1 - zorroValue / currentValue;

  return formatPercentage(ratio);
};

export const isPositiveChangeTrend = (
  zorroValue: number,
  currentValue: number
) => {
  return zorroValue - currentValue > 0;
};

export function aggregatePlans(sheetData: SheetData, plans: IPlan[]) {
  const planCounts = plans.reduce((acc, plan) => {
    const planAggregate = acc.get(plan.planId);
    if (planAggregate) {
      planAggregate.count++;
    } else if (sheetData.plans[plan.planId]) {
      acc.set(plan.planId, {
        count: 1,
        plan: sheetData.plans[plan.planId],
      });
    }

    return acc;
  }, new Map<string, { count: number; plan: Plan }>());

  const sortedPlans = [...planCounts.entries()].sort(
    (first, second) => second[1].count - first[1].count
  );

  return sortedPlans.slice(0, 8).map((sortedPlan) => {
    sortedPlan[1].plan.numberOfEmployees = sortedPlan[1].count;

    return sortedPlan[1].plan;
  });
}

export function calculatePlanComparisonData(
  sheetData: SheetData,
  employeeData: EmployeeData[],
  employeeScenarios: EmployeeScenario[],
  standardizedEmployeeAllowances: Map<string, StandardizedEmployeeAllowance>
): PlanComparisonData {
  const sumPremiums = employeeScenarios
    ?.filter(
      ({ employeeId }) =>
        !sheetData.employeeData?.get(employeeId)?.isEmployeeWaived
    )
    .reduce((acc, curr) => {
      return acc + curr.expectedCost;
    }, 0);

  const sumCurrentAllowance = employeeData
    .filter(({ isEmployeeWaived }) => !isEmployeeWaived)
    .reduce((acc, curr) => {
      return acc + Number(curr.allowance);
    }, 0);
  const sumCurrentPremium = employeeData
    .filter(({ isEmployeeWaived }) => !isEmployeeWaived)
    .reduce((acc, curr) => {
      return acc + Number(curr.premium);
    }, 0);

  const sumZorroAllowance = [...standardizedEmployeeAllowances.values()]
    .filter(({ employee }) => !employee.isEmployeeWaived)
    .reduce((acc, curr) => acc + curr.standardizedEmployeeAllowance, 0);

  const currentEmployeeCost = sumCurrentPremium - sumCurrentAllowance;
  const zorroEmployeeCost = sumPremiums - sumZorroAllowance;
  const currentEmployeePlans = [...sheetData.employeeData.values()].filter(
    ({ isEmployeeWaived }) => !isEmployeeWaived
  );

  const currentMonthlyCost = sumCurrentAllowance + currentEmployeeCost;
  const zorroTotalMonthlyCost = zorroEmployeeCost + sumZorroAllowance;
  return {
    current: {
      employer: sumCurrentAllowance,
      employee: currentEmployeeCost,
      averageMonthlyCostPerEmployee:
        currentMonthlyCost / currentEmployeePlans.length,
      totalMonthlyCost: currentMonthlyCost,
      currentEmployeePlansCount: currentEmployeePlans.length,
    },
    zorro: {
      employer: sumZorroAllowance,
      employee: zorroEmployeeCost,
      totalFees: sheetData.totalFees,
      averageMonthlyCostPerEmployee:
        zorroTotalMonthlyCost / currentEmployeePlans.length,
      totalMonthlyCost: zorroTotalMonthlyCost,
      currentEmployeePlansCount: currentEmployeePlans.length,
    },
  };
}

export function getValueOrFirst<T, U>(map: Map<T, U>, key: T): U | undefined {
  return map.size === 1 ? map.values().next().value : map.get(key);
}
