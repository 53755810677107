import { withFronteggApp } from '@frontegg/nextjs/pages';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Theme } from '@zorro/zorro-ui-design';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import { AppConfigurationProvider } from '~/context/AppConfigurationContext';
import { ScenarioCalculatedDataProvider } from '~/context/ScenarioCalculatedData';
import { SheetDataProvider } from '~/context/SheetDataContext';

const ReactQueryDevtoolsProduction = lazy(() =>
  // @ts-expect-error react-query-devtools
  import('@tanstack/react-query-devtools/production').then((build) => ({
    default: build.ReactQueryDevtools,
  }))
);

interface TornadoAppProps extends AppProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: AppProps['Component'] & {
    getLayout?: (page: ReactNode) => ReactNode;
  };
}

function TornadoApp({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component,
  pageProps: { session, ...pageProps },
}: TornadoAppProps) {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error react-query-devtools
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: 60_000,
            retry: false,
          },
        },
      })
  );

  return (
    <>
      <Head>
        <title>Welcome to Zorro Quote!</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <AppConfigurationProvider>
          <SheetDataProvider>
            <ScenarioCalculatedDataProvider>
              <MantineProvider theme={Theme}>
                <Notifications position="top-right" />
                <Component {...pageProps} />
              </MantineProvider>
            </ScenarioCalculatedDataProvider>
          </SheetDataProvider>
        </AppConfigurationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
      </QueryClientProvider>
    </>
  );
}

export default withFronteggApp(TornadoApp, {
  hostedLoginBox: true,
  lazyLoadAdminPortal: true,
  authOptions: {
    keepSessionAlive: true,
  },
});
